import {Button as MuiButton} from "@mui/material";
import {styled} from '@mui/material/styles';
import {deepmerge} from "@mui/utils";

const Button = styled(MuiButton)(({theme, variant, size}) => {
  // noinspection JSUnresolvedVariable
  const styles = {
    fontWeight: theme.typography.fontWeightBold,
    padding: `${theme.spacing(1)} ${theme.spacing(size === 'small' ? 1 : 4)}`,
    borderRadius: theme.spacing(2.5),
    color: theme.palette.neutral.contrastText,
    backgroundColor: theme.palette.neutral.main,
    border: `1px solid ${theme.palette.background.default}`,
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.neutral.light,
      boxShadow: "4px -4px 4px rgba(255, 255, 255, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.25)"
    },
  };

  const variantContained = {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  }

  // noinspection JSUnresolvedVariable
  const variantOutlined = {
    border: `2px solid ${theme.palette.neutral.contrastText}`,
    '&:hover': {
      border: `2px solid ${theme.palette.neutral.contrastText}`,
    }
  }

  if (variant === 'outlined') {
    return deepmerge(styles, variantOutlined);
  }

  if (variant === 'contained') {
    return deepmerge(styles, variantContained);
  }

  return styles;
});

export default Button;

export const CallToActionButton = styled(MuiButton)(({theme}) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(8)}`,
    borderRadius: theme.spacing(5),
    color: 'white',
    border: '1px solid white',
    backgroundColor: '#D93737',
    position: 'relative',
    fontWeight: 700,
    fontSize: '20px',
    '&:hover': {
      backgroundColor: '#D93737',
    },
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      borderRadius: 'inherit',
      backgroundColor: 'transparent',
      boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.25)',
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
    },
    '&:hover::after': {
      opacity: 1,
    }
  }
));

