import {Typography} from "@mui/material";
import {styled} from '@mui/material/styles';

const Link = styled(Typography)(({theme}) => {
  const styles = ({
    color: theme.palette.primary.light,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
  });

  return styles;
})

export default Link;
