import React from "react";
import {StaticImage} from "gatsby-plugin-image";

const alt = 'Fiberpay';
const width = 140;
const placeholder = 'none';

const FiberpayLogoImage = () => {
  return <StaticImage src={'../../images/fiberpay_white.svg'}
                      alt={alt}
                      width={width}
                      placeholder={placeholder}/>
}

export const FiberpayLogoImageDark = () => {
  return <StaticImage src={'../../images/fiberpay_gray.png'}
                      alt={alt}
                      width={width}
                      placeholder={placeholder}/>
}

export const FiberpayLogoImageCobalt = () => (
<StaticImage src={'../../images/fiberpay_cobalt.svg'}
                    alt={alt}
                    width={width}
                    placeholder={placeholder}/>

)

export default FiberpayLogoImage;
