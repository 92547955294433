import React from 'react';
import {Divider, Grid, styled, Typography} from "@mui/material";
import {FiberpayLogoImageDark} from "./FiberpayLogoImage";
import BaseContainer from "./BaseContainer";
import Link from "./Link";
import {Link as GatsbyLink} from 'gatsby';

// noinspection JSUnresolvedVariable
const Wrapper = styled('footer')(({theme}) => ({
  backgroundColor: theme.palette.neutral.light,
}));

const Footer = () => (<Wrapper sx={{py: 8}}>
  <BaseContainer component='div' id='o-nas'>
    <Grid container spacing={2} sx={{color: 'neutral.contrastText'}}>
      <Grid item xs={12}>
        <FiberpayLogoImageDark/>
      </Grid>
      <Grid container item spacing={2} xs={12} md={3}>
        <Grid item xs={12} sx={{fontWeight: 'bold'}}>
          FiberPay sp. z o.o.
        </Grid>
        <Grid item xs={12}>
          <Typography>
            ul. Grzybowska 4/106
          </Typography>
          <Typography>
            00-131 Warszawa
          </Typography>
          <Link component='a' href='mailto:info@fiberpay.pl'>
            info@fiberpay.pl
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            NIP: 7010634566
          </Typography>
          <Typography>
            REGON: 36589948900000
          </Typography>
          <Typography>
            KRS: 0000647662
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} spacing={2}>
        <Grid container item xs={6}>
          <Grid item xs={12} sx={{fontWeight: 'bold'}}>
            Produkty
          </Grid>
          {['Płatności', 'KYC', 'AML', 'Autoryzacja operacji'].map((el) => (<Grid item xs={12} key={el}>{el}</Grid>))}
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12} sx={{fontWeight: 'bold'}}>
            Branże
          </Grid>
          {['E-commerce', 'Marketplace', 'Platformy', 'Telekomy', 'Windykacja'].map((el) => (
            <Grid item xs={12} key={el}>{el}</Grid>))}
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12} sx={{fontWeight: 'bold'}}>
            O nas
          </Grid>
          {['Aktualności', 'O firmie'].map((el) => (<Grid item xs={12} key={el}>{el}</Grid>))}

        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12} sx={{fontWeight: 'bold'}}>
            Dla programistów
          </Grid>
          <Grid item xs={12} sx={{fontWeight: 'bold'}}>
            Cennik
          </Grid>
        </Grid>
      </Grid>
      <Divider/>
      <Grid container item xs={12} md={3}>
        <Grid container>
          <Grid item xs={12}>
            <Link component='a'
                  href='https://fiberpay.gitbook.io/fiberpay/'
                  target="_blank"
                  rel='noopener noreferrer'
                  sx={{color: 'neutral.dark'}}>Dokumentacja API</Link>
          </Grid>
          <Grid item xs={12}>
            <Link component={GatsbyLink}
              to='/regulaminy'
              sx={{color: 'neutral.dark'}}
            >
              Regulaminy
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link
              component={GatsbyLink}
              to='/polityka-prywatnosci'
              sx={{color: 'neutral.dark'}}
            >
              Polityka prywatności
            </Link>
          </Grid>
          <Grid item xs={12} sx={{color: 'neutral.dark'}}></Grid>
          <Grid item xs={12} sx={{color: 'neutral.dark'}}></Grid>
        </Grid>
      </Grid>
    </Grid>
  </BaseContainer>
</Wrapper>)

export default Footer;
