import React from 'react';
import {Container, useMediaQuery} from "@mui/material";

const BaseContainer = ({children, ...props}) => {
  // const theme = useTheme();
  let maxWidth = null;
  const isMobile = useMediaQuery('(max-width:1024px)');
  const isTablet = useMediaQuery('(min-width:1200px)');
  // const isDesktop = useMediaQuery('(min-width:1380px)');
  const isLarge = useMediaQuery('(min-width:1660px)');
  if (isMobile) maxWidth = 'md';
  if (isTablet) maxWidth = 'lg';
  if (isLarge) maxWidth = 'xl';

  return <Container maxWidth={maxWidth} {...props}>{children}</Container>
}

export default BaseContainer;
